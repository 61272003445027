import React from "react";
import { Helmet } from "react-helmet";
import config from "../../data/SiteConfig";
import Contact from "../components/Contact";
import Layout from "../components/Layout";

export default function ContactPage() {
  return (
    <Layout>
      <Helmet title={`Contact | ${config.siteTitle}`} />
      <Contact />
    </Layout>
  );
}
