import React from "react";
import styled from "styled-components";
import { CONSTANTS } from "../constants";
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";
import { SectionHeading, Wrapper } from "./shared";

export default function Contact() {
  return (
    <Wrapper>
      <SectionHeading>Contact me</SectionHeading>
      <Container>
        <ContactInfo />
        <Border />
        <ContactForm />
      </Container>
    </Wrapper>
  );
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 720px) {
    flex-wrap: nowrap;
  }
`;

const Border = styled.div`
  height: 2px;
  width: 100%;
  margin: ${CONSTANTS.space[6]} 0;
  background-color: ${CONSTANTS.color.border};

  @media (min-width: 720px) {
    height: 500px;
    width: 3px;
    margin: 0 ${CONSTANTS.space[6]};
  }
`;
