import React from "react";
import styled from "styled-components";
import { CONSTANTS } from "../constants";
import { Button } from "./shared";

export default function ContactForm() {
  const onSubmit = () => {
    grecaptcha.ready(function () {
      grecaptcha
        .execute("6LfI2yAaAAAAAIoMn70WTViKOz0WHqd-_wbQGRgt", {
          action: "homepage",
        })
        .then(function (token) {
          document.getElementById("captchaResponse").value = token;
        });
    });
  };

  return (
    <Form
      id="contact-form"
      method="POST"
      action="https://getform.io/f/6f168aa6-21b5-4c47-ade6-2fa49072069f"
      onSubmit={onSubmit}
    >
      <label htmlFor="name">
        Name <SecondaryText>(required)</SecondaryText>
      </label>
      <Input type="text" id="name" name="name" required></Input>
      <label htmlFor="email">Email</label>
      <Input type="email" id="email" name="email"></Input>
      <label htmlFor="tel">
        {" "}
        Contact telephone number <SecondaryText>(required)</SecondaryText>
      </label>
      <Input type="text" id="tel" name="tel" required></Input>
      <label htmlFor="message">
        Brief description of work <SecondaryText>(required)</SecondaryText>
      </label>
      <TextArea id="message" name="message" required></TextArea>
      <input
        type="hidden"
        id="captchaResponse"
        name="g-recaptcha-response"
      ></input>
      <SubmitButton type="submit">Send enquiry</SubmitButton>
    </Form>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 720px) {
    width: 50%;
  }
`;

const Input = styled.input`
  margin-bottom: ${CONSTANTS.space[3]};
  padding: ${CONSTANTS.space[0]};
  font-size: inherit;
  border: 1px solid ${CONSTANTS.color.accent};
`;

const TextArea = styled.textarea`
  height: 150px;
  margin-bottom: ${CONSTANTS.space[4]};
  padding: ${CONSTANTS.space[0]};
  font-size: inherit;
  font-family: inherit;
  border: 1px solid ${CONSTANTS.color.accent};
`;

const SecondaryText = styled.span`
  color: ${CONSTANTS.color.textSecondary};
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
`;
