import React from "react";
import styled from "styled-components";
import { CONSTANTS } from "../constants";

export default function ContactInfo() {
  return (
    <InfoWrapper>
      <p>
        I am based in East London, but offer my services to all of London and
        areas within the M25.
      </p>
      <p>
        I am available for work during the Covid-19 pandemic with social
        distancing guidelines in place.
      </p>
      <p>For quotes, please don't hesitate to contact me at:</p>
      <ContactList>
        <ContactItem>
          Email:{" "}
          <a href="mailto:info@sheriffshandywork.com">
            info@sheriffshandywork.com
          </a>
        </ContactItem>
        <ContactItem>
          Tel: <a href="tel:+447932577029">07932 577029</a>
        </ContactItem>
      </ContactList>
      <p>
        or fill out the enquiry form and I will aim to reply within 24 hours.
      </p>
    </InfoWrapper>
  );
}

const InfoWrapper = styled.div`
  width: 100%;

  > p {
    margin-top: ${CONSTANTS.space[1]};
    margin-bottom: 0;
  }

  @media (min-width: 720px) {
    width: 50%;
  }
`;

const ContactList = styled.ul`
  padding: ${CONSTANTS.space[3]};
  background-color: hsla(0, 0%, 90%, 0.75);

  > * + * {
    margin-top: ${CONSTANTS.space[1]};
  }
`;

const ContactItem = styled.li`
  list-style: none;
  font-weight: ${CONSTANTS.font.weight.semibold};
  font-size: ${CONSTANTS.font.size.body};

  @media (min-width: 375px) {
    font-size: ${CONSTANTS.font.size.medium};
  }
`;
